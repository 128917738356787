<template lang="pug">
  svg(width="6", height="12", viewBox="0 0 6 12", fill="none")
    path(d="M5.5 11L0.500001 6L5.5 1", stroke="black", stroke-linecap="round", stroke-linejoin="round")
</template>

<script>
export default {
  name: 'SvgArrowLeft'
};
</script>
