<template lang="pug">
  svg(width="9", height="18", viewBox="0 0 9 18", fill="none", :class="{ light }")
    path(fill-rule="evenodd", clip-rule="evenodd", d="M5.77778 6.11109V4.39162C5.77778 3.61509 5.94995 3.2222 7.15404 3.2222H8.66667V0.333313H6.35555C3.46667 0.333313 2.31111 2.26078 2.31111 4.37776V6.11109H0V8.99998H2.31111V17.6666H5.77778V8.99998H8.32346L8.66667 6.11109H5.77778Z")
</template>
<script>
export default {
  props: {
    // Тёмная цветовая гамма
    light: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  svg {
    max-width: 60px;

    & > path {
      fill: $facebook;
    }

    &.light > path {
      fill: $white;
    }
  }
</style>
