<template lang="pug">
  v-card(v-if="captchaId && captchaImage && visible")
    v-card-title {{ $t('formFields.captcha') }}
    v-card-text
      v-form(ref="form", v-model="valid", @submit.prevent="")
        v-layout.captcha(v-if="captchaImage", align-center)
          img(:src="captchaImage")
          v-icon.reload(@click="getNewCaptchaImage") mdi-reload
        span.captcha__info {{ $t('hints.captcha') }}
        v-text-field(
          v-model="captcha",
          ref="input",
          :rules="[rules.required]",
          :label="$t('formFields.captcha')",
          :persistent-hint="true",
          autocomplete="off",
          required)
        .errors(v-if="errors")
          p(v-for="error in errors", :key="error.title") #[strong {{ error.title }}]: #[span(v-html="error.detail")]
        v-btn(:disabled="!valid || loading", @click="$emit('submit', captcha)", type="submit") {{ $t('actions.proceed') }}
</template>

<script>
import { mapState } from 'vuex';
import SvgPlus from '@/components/svg/SvgPlus';

export default {
  name: 'CaptchaDialog',
  components: { SvgPlus },
  props: {
    visible: { type: Boolean, required: true },
    errors: { type: Array, required: true },
    loading: { type: Boolean, required: true }
  },
  data() {
    return {
      captcha: '',
      valid: false
    };
  },
  computed: {
    ...mapState('user', [
      'captchaId',
      'captchaImage'
    ]),
    rules() {
      return {
        required: v => !!v.trim() || this.$t('validation.required')
      };
    }
  },
  watch: {
    async visible(val) {
      if (!val) return;

      if (!this.captchaId) {
        await this.$store.dispatch('user/getCaptchaId');
      }

      await this.getNewCaptchaImage();
    },
    errors(val) {
      if (val && val.length) this.getNewCaptchaImage();
    }
  },
  methods: {
    getNewCaptchaImage() {
      if (!this.captchaId) return;

      this.$store.dispatch('user/getCaptchaImage', this.captchaId)
        .then(() => {
          this.captcha = '';

          setTimeout(() => {
            this.$refs.form.resetValidation();
            this.$refs.input.focus();
          }, 0);
        })
        .catch(errors => console.log('Error in fetching captcha image', errors));
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  ::v-deep .v-card__text {
    text-align: left !important;
    @media screen and (max-width: 530px) {
      padding: 0;
    }
  }

  .v-card {
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 530px) {
      padding: 0 20px;
    }
    .captcha {
      margin-bottom: 15px;
      position: relative;
      @media screen and (max-width: 530px) {
        margin-bottom: 0;
      }

      img {
        width: 360px;
        margin-right: 0;
        @media screen and (max-width: 530px) {
          width: 280px;
        }
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .captcha__info {
      margin-top: 1vh;
      width: 360px;
      text-align: left;
      margin-bottom: 5px;
      @media screen and (max-width: 530px) {
        width: 100%;
        font-size: 12px;
        line-height: 14px;
        margin-top: 20px;
      }
    }

    ::v-deep .v-btn,
    ::v-deep .v-input {
      width: 360px;
      max-width: 360px;
      @media screen and (max-width: 530px) {
        width: 100%;
        max-width: 100%;
      }
    }

    ::v-deep .v-input {
      @media screen and (max-width: 530px) {
        margin-bottom: 0;
      }
    }

    ::v-deep .v-btn {
      @media screen and (max-width: 530px) {
        margin-bottom: 20px;
      }
    }

    &__title {
      margin-top: 15vh;
      font-style: normal;
      font-weight: 500;
      font-size: 27px;
      line-height: 35px;
      text-align: center;
      color: #1F2126;
      padding-top: 0;
      padding-bottom: 5vh;
      @media screen and (max-width: 530px) {
        font-size: 20px;
        line-height: 26px;
        padding: 0;
        text-align: left;
        width: 100%;
        margin: 20px 0;
        font-weight: 500;
      }
    }

    .close {
      width: 24px;
      min-width: 24px;
      height: 24px;
      outline: none;
      transform: rotate(45deg);
      margin: auto 0 auto auto;
      opacity: 0.54;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 530px) {
        padding: 0;
      }

      > button {
        width: 360px;
        height: 40px !important;
        background: #0071E3;
        border-radius: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 16px;
        margin-top: 1vh;
      }
    }
  }

  .mdi-reload {
    background: #FFFFFF;
    box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
    border-radius: 52px;
    width: 40px;
    height: 40px;
    top: -13px !important;
    right: -13px !important;

    &::before {
      content: url('../assets/images/captcha_icon.svg');
    }
  }

  .errors{
    p{
      margin-bottom: 0;
    }
  }
</style>
