<template lang="pug">
  .ofcold__security-code.clearfix(:class="{ invalid: codeErrors.length }")
    .hint(v-if="twoFAType === 1 || (twoFAType === 2 && email)", :class="{'text-left': $route.name === 'settings-security'}") {{ $t('2FA.codeInputDesc.' + twoFAType, { email: emailMasked }) }}
    .ofcold__security-code-wrapper
      .ofcold__security-code-field(v-for="n in securityCodeLength")
        input.form-control(
          :ref="'input_' + n"
          maxlength="1",
          autocorrect="off",
          autocomplete="off",
          autocapitalize="off",
          spellcheck="false",
          type="tel",
          v-model="securityCode[n-1]",
          @focus="setSelected",
          @input.stop="inputEvent",
          @keydown.stop="downEvent",
          @keypress.stop="pressEvent",
          @paste="pasteEvent(n-1, $event)")
    .hint.with_error(v-if="codeErrors.length", v-for="error in codeErrors", :class="{'text-left': $route.name === 'settings-security'}") #[span {{ error.title }}]: #[span(v-html="error.detail")]
    .hint.link(v-if="twoFAType === 1", :class="{'justify-start': $route.name === 'settings-security'}")
      v-icon.mr-2(size="21", color="#19a2dc") mdi-help-circle
      a(:href="`mailto:support@kickex.com?subject=Lost%20access%20to%20Google%20Authenticator`" target="_blank") {{ $t('2FA.lostGAText') }}?
    .ofcold__security-code-botton.d-flex.flex-column.justify-space-between
      // v-btn.ofcold__security-code-next(
        // depressed,
        // @click="nextMethods"
        // :disabled="next") Next
      v-btn.ofcold__security-code-resend(
        v-if="twoFAType === 2",
        depressed,
        @click="getEmailPin",
        :disabled="loading || countdownActive")
        |  {{ $t('actions.resend') }}
        Countdown(
          v-if="countdownActive",
          :timer="codeLiveTime",
          :timerStart="emailSentTimestamp",
          @expired="countdownActive = false",
          digits-only)
</template>

<script>
import Countdown from '@/components/Countdown';

export default {
  components: { Countdown },
  name: 'OfcoldSecurityCode',
  props: {
    twoFAType: {
      type: [Number, String],
      required: true,
      default: 1
    },
    value: {
      type: [Number, String],
      required: true
    },
    blurOnComplete: {
      type: Boolean,
      default: false
    },
    securityCodeLength: {
      type: Number,
      default: 6
    },
    isArray: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    email: {
      type: String,
      default: ''
    },
    loginToken: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      next: true,
      emailSentTimestamp: 0,
      countdownActive: false,
      codeLiveTime: 5 * 60 * 1000,
      clearErrorTimerId: null,
      securityCode: new Array(this.securityCodeLength),
      codeErrors: [],
      code: ''
    };
  },
  computed: {
    emailMasked() {
      if (!this.email) return '';

      const name = this.email.slice(0, this.email.indexOf('@'));

      if (name.length <= 2) {
        return `${'*'.repeat(name.length)}${this.email.slice(this.email.indexOf('@'))}`;
      }

      return `${this.email.slice(0, 1)}${'*'.repeat(this.email.indexOf('@') - 2)}${this.email[this.email.indexOf('@') - 1]}${this.email.slice(this.email.indexOf('@'))}`;
    }
  },
  watch: {
    errors: {
      handler(val) {
        if (val && val.length) {
          this.codeErrors = this.errors;

          this.clearErrorTimerId = setTimeout(() => {
            this.securityCode = new Array(this.securityCodeLength);
            this.codeErrors = [];
            setTimeout(() => this.$refs.input_1[0].focus(), 0);
          }, 2500);
        }
      },
      deep: true
    },
    codeErrors: {
      handler(val) {
        if (this.codeErrors.length > 0) {
          this.next = true;
        }
        return val;
      },
      deep: true
    }
  },
  mounted() {
    setTimeout(() => this.$refs.input_1[0].focus(), 0);

    if (this.value !== 0) {
      this.securityCode = this.value.toString().substr(0, this.securityCodeLength).split('');
    }

    if (this.twoFAType === 2) {
      this.getEmailPin();
    }

    this.codeErrors = this.errors;
  },
  methods: {
    handleCodeFocus() {
      if (this.clearErrorTimerId) {
        clearTimeout(this.clearErrorTimerId);
      }

      if (this.securityCode.length === 6 && this.codeErrors.length) {
        this.securityCode = new Array(this.securityCodeLength);
      }
    },
    getEmailPin() {
      if (this.loading) return;

      this.loading = true;

      this.$store.dispatch('user/getEmailPin', this.loginToken)
        .then(({ data }) => {
          this.emailSentTimestamp = Date.now();
          this.codeLiveTime = data.data.attributes.expired_at * 1000 - this.emailSentTimestamp;
          this.countdownActive = true;
        })
        .catch(errors => console.log('!!!!!!!!! errors', errors));

      this.loading = false;
    },
    inputEvent(event) {
      const value = event.target.value;

      if (value.length > 1) {
        event.target.value = value.substr(0, 1);
      }

      if (this.getCodeString().length === this.securityCodeLength) {
        if (this.blurOnComplete) {
          event.target.blur();
        } else {
          this.nextElement(event);
        }
      } else if (event.target.value) {
        this.nextElement(event);
      }
    },
    /**
     * Listen paste event.
     *
     * @param  {[type]} index
     * @param  {[type]} event
     *
     * @return {[array]}
     */
    pasteEvent(index, event) {
      let i;
      let pasteData;
      const elements = event.target.parentNode.parentNode.childNodes;
      let len = 0;
      const vm = this;

      for (event.clipboardData && event.clipboardData.getData
        ? pasteData = event.clipboardData.getData('Text')
        : window.clipboardData && window.clipboardData.getData && (pasteData = window.clipboardData.getData('Text'))
      , pasteData = pasteData.replace(/\s/g, '').substr(0, elements.length - index).split(''),
      i = 0; i < elements.length && !Number.isNaN(Number(pasteData[i])); i++) {
        len++;
        elements[i + index].firstChild.value = pasteData[i];
        vm.securityCode[i + index] = pasteData[i];
      }

      return [
        setTimeout(() => {
          if (vm.getCodeString().length === vm.securityCodeLength) {
            if (vm.blurOnComplete) {
              event.target.blur();
            } else {
              vm.previousElement(event, vm.getCodeString().length - 1);
            }
          } else {
            vm.previousElement(event, index + len);
          }
        }, 0),
        event.preventDefault(),
        false
      ];
    },
    pressEvent(event) {
      const keyCode = event.which || event.keyCode;
      return this.isMainKeyCode(keyCode)
          || this.isTab(keyCode)
          || this.isBackspace(keyCode)
          || this.isMetaKey(event, keyCode)
        ? undefined
        : (event.preventDefault(), false);
    },
    downEvent(event) {
      const parentNode = event.target.parentNode;
      const keyCode = event.which || event.keyCode;
      let _sibling;

      if (keyCode === 8 && !event.target.value) {
        _sibling = parentNode.previousSibling;
        if (_sibling) {
          _sibling.firstChild.focus();
        }
      } else if (keyCode >= 37 && keyCode <= 41) {
        switch (keyCode) {
          case 37:
            _sibling = parentNode.previousSibling;
            break;
          case 39:
            _sibling = parentNode.nextSibling;
            break;
          default: return false;
        }
        if (_sibling) {
          _sibling.firstChild.focus();
        }
        return [event.preventDefault(), false];
      }

      return false;
    },
    previousElement(event, length) {
      const elements = event.target.parentNode.parentNode.childNodes;
      let lengthCopy = length;

      if (lengthCopy >= elements.lengthCopy) {
        lengthCopy = elements.lengthCopy - 1;
      }

      if (elements[lengthCopy] && elements[lengthCopy].firstChild) {
        elements[lengthCopy].firstChild.focus();
      }
    },
    nextElement(event) {
      const parentNode = event.target.parentNode;
      const nextSibling = parentNode.nextSibling;

      if (nextSibling) {
        nextSibling.firstChild.focus();
      } else {
        parentNode.focus();
      }
    },
    isMainKeyCode(keyCode) {
      return keyCode >= 48 && keyCode <= 57;
    },
    isTab(keyCode) {
      return keyCode === 9;
    },
    isBackspace(keyCode) {
      return keyCode === 8;
    },
    isMetaKey(event, keyCode) {
      return event.metaKey && keyCode === 118;
    },
    setSelected(event) {
      event.target.select();

      this.handleCodeFocus();
    },
    getCodeString() {
      this.code = this.isArray ? this.securityCode : this.securityCode.join('');
      if (this.securityCodeLength === this.securityCode.join('').length) {
        this.next = false;
      } else {
        this.next = true;
      }
      this.$emit(
        'input',
        this.code
      );
      return this.code;
    }

    // nextMethods() {
    //   this.$emit(
    //     'input',
    //     this.code
    //   );
    // }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .ofcold__security-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-wrapper {
      display: inline-block;
      margin: 30px auto 0;
      min-width: 240px;
      text-align: center;
      .ofcold__security-code-field {
        margin-right: 12px!important;
        float: left;
        width: 50px !important;
        height: 50px !important;
        @media screen and (max-width: 960px) {
          width: 40px !important;
          height: 40px !important;
        }
        @media screen and (max-width: 800px) {
          width: 50px !important;
          height: 50px !important;
        }
        @media screen and (max-width: 400px) {
          width: 40px !important;
          height: 40px !important;
        }
        .form-control {
          font-size: 30px;
          text-align: center;
          padding: 0;
          width: 100% !important;
          height: 100% !important;
          background: #FFFFFF;
          border: 1px solid rgba(31, 33, 38, 0.1);
          box-sizing: border-box;
          border-radius: 5px;
          &:focus{
            border-color: #1F2126;
          }
        }
        &:after{
          display: none;
        }
        &:last-child{
          margin-right: 0!important;
        }
      }
    }
    &-botton{
      margin-top: 20px;
      @media screen and (max-width: 530px) {
        width: 100%;
      }
    }
    .hint {
      margin: 0;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #1F2126;
      @media screen and (max-width: 530px) {
        text-align: left;
      }
      &.with_error { color: $error-color; }

      &.link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        ::v-deep a {
          color: $blue;
          text-decoration: none;
          font-size: 16px;
          font-weight: 600;
        }
      }

    }

    &-resend {
      width: 360px;
      height: 40px !important;
      background: rgba(31, 33, 38, 0.05)!important;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px!important;
      line-height: 16px;
      text-align: center;
      color: rgba(31, 33, 38, 0.6)!important;
      @media screen and (max-width: 960px) {
        width: 300px !important;
      }
      @media screen and (max-width: 800px) {
        width: 360px !important;
      }
      @media screen and (max-width: 400px) {
        width: 100% !important;
      }
    }

    &-next{
      width: 360px!important;
      height: 40px!important;
      background: #0071E3!important;
      border-radius: 5px!important;
      margin: 30px 0 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px!important;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      @media screen and (max-width: 960px) {
        width: 300px !important;
      }
      @media screen and (max-width: 800px) {
        width: 360px !important;
      }
      @media screen and (max-width: 400px) {
        width: 100% !important;
      }
    }

    ::v-deep strong {
      font-weight: 500 !important;
      margin-left: 10px;
    }
  }

  .api__security_code.ofcold__security-code .hint {
    justify-content: flex-start;
    text-align: left;
  }

  .ofcold__security-code-wrapper {
    @media screen and (max-width: 640px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
</style>
