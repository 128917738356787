<template lang="pug">
  strong(v-html="text")
</template>

<script>
export default {
  props: {
    // set countdown timer in milliseconds
    timer: { type: Number, required: true },
    timerStart: { type: Number, required: true },
    digitsOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      now: Date.now(),
      interval: null
    };
  },
  computed: {
    text() {
      if (this.timeDiff > this.timer) return '';

      const seconds = (this.timer - this.timeDiff) / 1000;

      const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
      const numseconds = Math.floor(((seconds % 31536000) % 86400) % 3600) % 60;

      if (this.digitsOnly) {
        const timeLeft = `${numminutes}:${numseconds < 10 ? `0${numseconds}` : numseconds}`;
        return this.$t('emailConfirmation.countdown', { timeLeft });
      }

      return `${numminutes ? `${numminutes} ${this.$t('common.min')}` : ''} ${numseconds} ${this.$t('common.sec')}`;
    },
    timeDiff() {
      return this.now - this.timerStart;
    }
  },
  watch: {
    timeDiff(val) {
      if (val >= this.timer) this.$emit('expired');
    }
  },
  mounted() {
    if (this.timeDiff < this.timer) {
      this.interval = setInterval(() => {
        this.now = Date.now();
      }, 1000);
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped lang="scss">
  strong {
    margin: 0 5px;
    font-variant-numeric: tabular-nums;

    ::v-deep span {
      margin-right: 5px;
    }
  }
</style>
