<template lang="pug">
  v-layout.social(column)
    h2 {{ $t('actions.socialNetwork') }}
    v-layout
      v-btn.fb(text, color="primary", @click="getSocialRedirect('facebook')", :disabled="buttonsDisabled")
        SvgFacebook
      v-btn(text, color="error", @click="getSocialRedirect('google')", :disabled="buttonsDisabled")
        SvgGoogle
      v-btn.block(text, @click="getSocialRedirect('apple')", :disabled="buttonsDisabled")
        SvgApple
    .errors(v-if="errors")
      div(v-for="error in errors") #[strong {{ error.title }}]: #[span(v-html="error.detail")]
</template>

<script>
import { mapState } from 'vuex';
import SvgFacebook from '@/components/svg/SvgSocialFacebook';
import SvgGoogle from '@/components/svg/SvgGoogle';
import SvgApple from '@/components/svg/SvgLogoApple';
import { generateId } from '@/utils';

export default {
  components: {
    SvgFacebook,
    SvgGoogle,
    SvgApple
  },
  data() {
    return {
      buttonsDisabled: false,
      errors: null
    };
  },
  computed: {
    ...mapState('user', ['socialSignInErrors'])
  },
  methods: {
    getSocialRedirect(socialName) {
      this.buttonsDisabled = true;

      this.$store.commit('user/setRegistrationType', socialName);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'buttonClicks',
        // pageName: `${this.serviceIndex}.Main page`,
        pageName: 'id.Main page',
        clickText: socialName
      });

      const state = generateId();

      localStorage.setItem('oauth_state', state);

      this.$store.dispatch('user/getSocialRedirectUrls', { socialName, type: 'login' })
        .then(redirectUrl => {
          window.location = `${redirectUrl}&state=${state}`;
        })
        .catch(error => {
          if (error.message) {
            this.errors = [{ title: this.$t('messages.error.undefined'), detail: error.message }];
            return;
          }

          if (error.length) this.errors = error;
        })
        .finally(() => { this.buttonsDisabled = false; });
    }
  },
  mounted() {
    if (this.socialSignInErrors.length) {
      this.errors = this.socialSignInErrors;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .social {
    width: 100%;
    align-items: center;
    h2{
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #1F2126;
      margin: 4vh 0 3vh;
    }
    .errors {
      font-size: 12px;
      margin-top: 20px;
    }

    .v-btn {
      background-color: $white !important;
      font-size: 16px !important;
      font-weight: 700;
      width: 40px!important;
      height: 40px !important;
      min-width: 40px !important;
      min-height: 40px !important;
      border-radius: 50%;
      padding: 0!important;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      margin-right: 15px;

      &:not(.block) { width: 48%; }

      &.block {
        color: $sub-black !important;
      }

      &:hover {
        filter: none;

        &::before { content: none !important;}
      }

      &.v-btn--disabled {
        color: $disabled-btn !important;
      }

      &.fb:not(.v-btn--disabled) {
        color: $facebook !important;
      }
    }

    @media screen and (max-width: 600px) {
      margin-bottom: 15px !important;

      & > .layout { flex-wrap: wrap; }

      .v-btn { width: 100%; }
    }
  }
</style>
